import React, { useRef, useEffect, memo, useState } from 'react';
import { SuspenseLoading } from '../../SuspenseLoading';
import Rating from 'react-rating';
import {
  $BestSellerFeedback,
  $Slider,
  $RatingContainer,
  $FbContainer,
} from '../../templates/styles';
import InputSlider from 'react-input-slider';
import StarMirajOutline from '../../../assets/images/miraj/miraj-star-outline.svg';
import StarMirajFilled from '../../../assets/images/miraj/miraj-star-filled.svg';
import StarCineOutline from '../../../assets/images/cine-star-outline.svg';
import StarCineFilled from '../../../assets/images/cine-star-filled.svg';
import StarOutline from '../../../assets/images/star-empty.svg';
import StarConnplexOutline from '../../../assets/images/connplex/star.svg';
import StarConnplexFilled from '../../../assets/images/connplex/feedback-star-filled.svg';
import HeartNycinemaOutline from '../../../assets/images/ny-cinema/feedback-heart-outline.svg';
import HeartNycinemaFilled from '../../../assets/images/ny-cinema/feedback-heart-filled.svg';
import { ThankYouBestIcon } from '../../../assets/images/best-seller';
import { campaignTypes } from '../../campaigns/constants';
import { CampaignType } from '../../campaigns/CampaignType';

import StarFilled from '../../../assets/images/star-filled.svg';
import useIcon from './Icons';
import SmileyRating from '../../feedback/SmileyScale';
import { bgCampaign } from 'utils/campaignbg';

import { useDebouncedEffect } from 'utils';

const FeedbackForm = React.lazy(() => import('../../feedback/Feedback'));
const Feedback = (props) => {
  const {
    flags,
    handleCancelFeedback,
    feedbackSettings,
    feedbackRating,
    onRatingChange,
    NYBStatusFeedback,
    setNYBStatusFeedback,
    storeId,
    storeAddress,
    brandLogo,
    user,
    billUid,
    bestFeedFinished,
    bsfRating,
    setBsfRating,
    feedbackExist,
    deletedBill,
    handleRatingClick,
    billRatingHt,
    setBillRatingHt,
    brandName,
    isCustomView,
    parsedData,
    campaignDetails,
    brandId,
    companyId,
    surveyData,
  } = props;
  const {
    hideFeedBack,
    isBestSeller,
    isNykaaStore,
    isNPSFeedback,
    isBK,
    isKompaneroFeedback,
    isRareRabbitFeedback,
    isCinepolisGroup,
    isCinepolisMexicoTicket,
    isMiraj,
    isCarnival,
    isConnplex,
    isGadchiroli,
    isCityOfPatna,
    isPrasads,
    isWatersEdge,
    isDecathlonIndiaEcom,
    isCinepolisTicket,
    isMisbu,
    isTrentHyperMarket,
    isDemoCinepolis,
    isTrent,
    isUtsa,
    isRzpPos,
    isNyCinemas,
    fbThroughSurvey,
    isSuperK,
    isZudio,
    isKaya,
    isBewakoof,
    isManyavar,
    isWowMomos,
    isGoColors,
    isRzpFtx25,
    isSammm,
  } = flags;
  const [barSlider, setBarSlider] = useState(0);

  const isCustomIconUnfilled =
    feedbackSettings?.unfilledRating && feedbackSettings?.scaleType === 'customIcon';
  const isCustomIconFilled =
    feedbackSettings?.filledRating && feedbackSettings?.scaleType === 'customIcon';

  const billRatingRef = useRef();
  const BeforeRatingStyle = {
    [isCustomIconUnfilled]: feedbackSettings?.unfilledRating,
    [isConnplex]: StarConnplexOutline,
    [isMiraj]: StarMirajOutline,
    [isNyCinemas]: HeartNycinemaOutline,
    [isCinepolisTicket]: StarCineOutline,
  };

  const AfterRatingStyle = {
    [isCustomIconFilled]: feedbackSettings?.filledRating,
    [isConnplex]: StarConnplexFilled,
    [isMiraj]: StarMirajFilled,
    [isNyCinemas]: HeartNycinemaFilled,
    [isCinepolisTicket]: StarCineFilled,
  };
  const onFeedbackRatingChange = (rating) => {
    if (rating >= 4) {
      if (isKompaneroFeedback)
        window.open(
          'https://www.google.com/search?q=kompanero%20store%20mumbai&rlz=1C1VDKB_enIN995IN995&[…]yZSgA&ved=2ahUKEwiK_KLe7ZD3AhX6wosBHYRgAPEQvS56BAgEEAE&rlst=f',
        );
      if (isRareRabbitFeedback)
        window.open(
          'https://www.google.com/search?q=rare+rabbit+esplanade+odisha&rlz=1C1VDKB_enIN995IN99#lrd=0x3a190bdc1f036821:0x34e0ecfdef70262,3,,,',
        );
    }
    handleRatingClick(rating);
  };

  useDebouncedEffect(
    () => {
      if (barSlider) {
        onFeedbackRatingChange(barSlider);
      }
    },
    [barSlider],
    950,
  );

  const { getThankYouIcon } = useIcon(flags);

  /*
  Get styling properties for Feedback
  */

  const getFeedbackStyles = () => {
    if (isCarnival) {
      return {
        backgroundColor: '#000',
        color: '#fff',
      };
    } else if (isRzpPos) {
      return {
        backgroundColor: 'white',
      };
    } else if (isKaya) {
      return {
        backgroundColor: 'white',
      };
    } else if (isManyavar) {
      return {
        backgroundColor: 'white',
      };
    } else if (isWowMomos) {
      return {
        backgroundColor: 'white',
      };
    } else if (isGoColors) {
      return {
        backgroundColor: 'white',
      };
    } else if (isConnplex) {
      if (
        parsedData?.customData?.ticketType === 'CINEPOLIS_FOOD' ||
        parsedData?.customData?.ticketType === 'CINEPOLIS_TICKET'
      ) {
        return {
          backgroundColor: 'transparant',
          color: 'black',
          fontweight: 'initial',
        };
      } else {
        return {
          backgroundColor: '#231F20',
          color: '#fff',
        };
      }
    } else if (isPrasads) {
      return {
        backgroundColor: 'transparent',
        color: '#fff',
      };
    } else {
      return {
        backgroundColor: false,
        color: false,
      };
    }

    // return {
    // 	backgroundColor: isCarnival ? "#000" : isConnplex ? "#231F20" : false,
    // 	color: isCarnival || isConnplex ? "#fff" : false,
    // };
  };

  useEffect(() => {
    if (!billRatingHt && billRatingRef?.current)
      setBillRatingHt(billRatingRef?.current?.offsetHeight);
  });

  const displayRating = (includeLine, label) => (
    <div
      className={
        isConnplex && parsedData?.customData?.ticketType === 'CINEPOLIS_FOOD'
          ? 'connplexhowlike'
          : 'bill-app-rating'
      }
      ref={billRatingRef}>
      {/* <div className="bill-app-rating media-no-print" ref={billRatingRef}></div> */}

      {feedbackSettings?.scaleType === 'bar' ? (
        <BarTypeFeedback />
      ) : storeId && !fbThroughSurvey ? (
        <>
          {feedbackExist ? (
            <div className={`title title-fb-submmited ${isBK ? 'bk-rating' : ''}`}>
              {isCinepolisMexicoTicket ? `comentarios enviados` : `Feedback submitted`}
            </div>
          ) : (
            <div className={`title${isBK ? ' bk-rating' : ''}`}>
              {isCinepolisMexicoTicket ? (
                `Califica tu experiencia !`
              ) : isCinepolisTicket || isRzpFtx25 ? (
                <small className="cinepolis-ticket-rating">
                  <b>RATE YOUR EXPERIENCE</b>
                </small>
              ) : isConnplex && parsedData?.customData?.ticketType === 'CINEPOLIS_TICKET' ? (
                <>
                  {
                    <div className="connplextitle">
                      <p> {storeAddress}</p>
                    </div>
                  }{' '}
                </>
              ) : (
                <>{label || `Rate ${isBK ? ' your experience' : isMiraj ? ' Us' : brandName}!`}</>
              )}
            </div>
          )}
          {feedbackSettings.scaleType === 'smiley' ? (
            fbThroughSurvey ? (
              ''
            ) : (
              <SmileyRating
                feedbackSettings={feedbackSettings}
                feedbackRating={feedbackRating}
                feedbackExist={feedbackExist}
                onFeedbackRatingChange={onFeedbackRatingChange}
                isReverseOrder={isTrent || isTrentHyperMarket || isUtsa || isBewakoof}
              />
            )
          ) : (
            <Rating
              initialRating={feedbackRating}
              emptySymbol={
                <img
                  src={BeforeRatingStyle[companyId || brandId ? true : false] || StarOutline}
                  className={isConnplex ? 'star-icon-connplex' : 'star-icon'}
                  alt="Rating star"
                />
              }
              fullSymbol={
                <img
                  src={AfterRatingStyle[companyId || brandId ? true : false] || StarFilled}
                  className={isConnplex ? 'star-icon-connplex' : 'star-icon'}
                  alt="Rating star"
                />
              }
              onClick={onFeedbackRatingChange}
              readonly={deletedBill || (feedbackExist && !isDecathlonIndiaEcom)}
              className={`media-no-print ${(isMiraj || isCinepolisTicket) && 'miraj-rating'} ${isNyCinemas && 'feedback-heart'} ${feedbackSettings?.scaleType === 'customIcon' && 'star-custom-icon'}`}
              stop={feedbackSettings?.scaleLength || 5}
            />
          )}
        </>
      ) : fbThroughSurvey ? (
        <CampaignType
          flags={flags}
          campaignDetails={campaignDetails}
          surveyData={surveyData}
          type={campaignTypes?.surveyInBill}
        />
      ) : (
        <div className="rating-skeleton media-no-print" />
      )}
      {includeLine && <hr />}
    </div>
  );

  const BarTypeFeedback = () => (
    <$BestSellerFeedback>
      <p className={'title ' + (isKaya ? 'titlekaya' : '')}>
        {bestFeedFinished || feedbackExist
          ? 'Thank You For Your Valuable Feedback'
          : isBK
            ? 'Rate your Experience!'
            : isSammm
              ? 'Would you tell your squad about us? '
              : `On a scale of 0 to 10, how likely are you to recommend ${brandName} to your friends & family.`}
      </p>

      <$Slider>
        <InputSlider
          disabled={bestFeedFinished || feedbackExist}
          axis="x"
          xstep={0.01}
          xmin={0}
          xmax={feedbackSettings?.quickFeedbackMeta?.ratingModels.length}
          x={feedbackRating ? feedbackRating : barSlider}
          onChange={({ x }) => setBarSlider(Math.ceil(x))}
          styles={{
            track: {
              backgroundColor: '#fff',
              boxShadow: '0px 3px 6px #00000029',
              width: '330px',
            },
            active: {
              backgroundColor: bsfRating <= 6 ? '#F86868' : bsfRating <= 8 ? '#FFC200' : '#66F369',
            },
            thumb: {
              width: 18,
              height: 18,
              backgroundColor: bsfRating <= 6 ? '#F86868' : bsfRating <= 8 ? '#FFC200' : '#66F369',
              border: '4px solid #fff',
              boxShadow: '0px 3px 6px #00000029',
            },
          }}
        />
      </$Slider>

      <div className="ratings">
        {Array(feedbackSettings?.scaleLength)
          .fill()
          .map((v, i) => i + 1)
          .map((key) => (
            <span
              onClick={() => !(bestFeedFinished || feedbackExist) && onFeedbackRatingChange(key)}
              key={key}
              className={key <= 6 ? 'unlikely' : key <= 8 ? 'probably' : 'forSure'}>
              {key}
            </span>
          ))}
        <small className="unlikely">Unlikely</small>
        <small className="probably">Probably</small>
        <small className="forSure">For Sure</small>
      </div>
    </$BestSellerFeedback>
  );

  const getFeedbackForm = () => {
    return props.isFeedbackFormOpen ? (
      <React.Suspense fallback={<SuspenseLoading />}>
        <FeedbackForm
          onCancel={handleCancelFeedback}
          feedbackSettings={feedbackSettings}
          feedbackRating={feedbackRating}
          onRatingChange={onRatingChange}
          NYBStatus={NYBStatusFeedback}
          setNYBStatus={setNYBStatusFeedback}
          directlySubmit={isDecathlonIndiaEcom}
          billDetail={{
            storeId,
            storeAddress,
            brandLogo,
            user,
            billUid,
          }}
          className="media-no-print"
          feedbackExist={feedbackExist}
          flags={flags}
        />
      </React.Suspense>
    ) : null;
  };
  if (isGadchiroli || isCityOfPatna) return null;

  if (isCinepolisGroup || isMiraj || isRzpFtx25) {
    const shouldShow =
      ((isCinepolisTicket || isRzpFtx25) && isCustomView) ||
      (!isCinepolisTicket && !isRzpFtx25 && !isCustomView);
    if (!shouldShow) return null;
    return (
      <>
        {getFeedbackForm()}
        <$FbContainer
          className={
            isMiraj
              ? 'miraj__rating-container'
              : isCustomView
                ? 'cine_rating-container'
                : 'cinepolis__rating-container'
          }
          top={bgCampaign(campaignDetails, parsedData).top}>
          {displayRating()}
        </$FbContainer>
      </>
    );
  }
  if (isBK && !isDemoCinepolis) {
    return (
      <>
        {/* {getFeedbackForm()}
                <div className={`bill-app-body ${isBK ? 'bk-header' : ''}`}>
                    {displayRating()}
                </div> */}
      </>
    );
  }
  //for bk store in billme demo
  if (isDemoCinepolis) {
    return (
      <>
        {getFeedbackForm()}
        <div className={`bill-app-body ${isBK ? 'bk-header' : ''}`}>{displayRating()}</div>
      </>
    );
  }

  if (isWatersEdge) {
    return (
      <>
        {getFeedbackForm()}

        {!hideFeedBack && (
          <$RatingContainer
            isBestSeller={isBestSeller || isNykaaStore}
            backgroundColor={getFeedbackStyles().backgroundColor}
            color={getFeedbackStyles().color}>
            {displayRating(false, 'Please Rate Us!')}
          </$RatingContainer>
        )}
      </>
    );
  }

  return (
    <>
      {getFeedbackForm()}

      {!hideFeedBack && (
        <$RatingContainer
          isBestSeller={isBestSeller || isNykaaStore}
          backgroundColor={getFeedbackStyles().backgroundColor}
          bgImg={feedbackSettings?.backgroundUrl ? feedbackSettings?.backgroundUrl : ''}
          bgSize={feedbackSettings?.backgroundUrl ? 'cover' : ''}
          color={getFeedbackStyles().color}
          margin={
            isRzpPos ? '15px' : isManyavar || isGoColors || isWowMomos ? '0px 10px 0px 10px' : ''
          }
          fontSize={isBewakoof ? '20px' : ''}
          padding={fbThroughSurvey}
          borderRadius={
            isRzpPos || isKaya ? '6px' : isManyavar || isGoColors || isWowMomos ? '10px' : ''
          }
          fontFamily={isDecathlonIndiaEcom ? 'Inter' : 'AvenirLTStd-Heavy, sans-serif'}
          borderBottom={
            isRzpPos
              ? '3px solid #ECECEC'
              : isManyavar || isGoColors || isWowMomos
                ? '2px solid #EBEAE4'
                : ''
          }
          border={isKaya ? '1px solid #D3D3D3' : ''}>
          {displayRating(
            false,
            feedbackSettings?.headerText
              ? feedbackSettings?.headerText
              : isMisbu
                ? 'Your Feedback'
                : isRzpPos
                  ? 'Rate your payment experience'
                  : isKaya
                    ? 'How was your experience'
                    : isZudio
                      ? 'Tell us about your overall experience'
                      : isSuperK
                        ? 'How was your experience'
                        : isBewakoof
                          ? 'Rate Your Shopping Experience'
                          : 'How much do you like us ?',
          )}
        </$RatingContainer>
      )}
    </>
  );
};

export default memo(Feedback);
