import React, { useState, useEffect, useRef } from 'react';
import Layout from './viewFiles/Layout';
import Header from './viewFiles/Header';
import SocialTopRow from './viewFiles/SocialsTopRow';
import SocialBottomRow from './viewFiles/SocialBottomRow';
import { useToasts } from '../Toast';
import 'react-datepicker/dist/react-datepicker.css';
import customVendorFlags from '../../utils/customVendorFlags';
import { CampaignType } from '../campaigns/CampaignType';
import { campaignTypes } from '../campaigns/constants';
import PopOverBill from './viewFiles/PopOverBill';
import { useTheme } from 'styled-components';
import Feedback from './viewFiles/Feedback';
import Body from './viewFiles/Body';
import { $Block } from '../templates/styles';
import Tear from '../../assets/images/wf/bill-tear.svg';
import { useDebouncedEffect } from '../../utils';
import { toJpeg } from 'html-to-image';
import download from 'downloadjs';
import axios from 'axios';
import { config } from '../../config';
import Payment from './Payment';
import AdsComponent from '../campaigns/GoogleAdComponent';
import { set } from 'lodash';
import addPropsToChildren from 'utils/addPropsToChildren';

const BurgerKingFonts = React.lazy(() => import('../../css/styles/bkfonts'));

const PropForwardWrapper = ({ children, ...rest }) => {
  return <div {...rest?.divProps}>{addPropsToChildren(children, rest)}</div>;
};

const BillView = (props) => {
  const {
    brandId,
    storeId,
    companyId,
    customId,
    parsedData,
    isEcomBill,
    campaignDetailsPOB,
    campaignDetails,
    isRzpPos,
    isManyavar,
    isWowMomos,
    isGoColors,
    fbThroughSurvey,
    payNowThroughRzp,
    isSammm,
    billUid,
    billImageUrl,
  } = props;
  const theme = useTheme();
  const flags = customVendorFlags({
    brandId,
    storeId,
    companyId,
    customId,
    parsedData,
    isEcomBill,
  });
  const [POBSetting, setPOBSetting] = useState({});
  const [isPopupOverBillModalOpen, setIsPopupOverBillModalOpen] = useState(false);
  const handlePOBClick = () => setIsPopupOverBillModalOpen(!isPopupOverBillModalOpen);
  const [NYBStatusFeedback, setNYBStatusFeedback] = useState('');
  const [bestFeed, setBestFeed] = useState({});
  const [bsfRating, setBsfRating] = useState(undefined);
  const [billRatingHt, setBillRatingHt] = useState();
  const [isBillLarger, setIsBillLarger] = useState(false);
  const [billImageSize, setBillImageSize] = useState('');
  const [billTemplateSize, setBillTemplateSize] = useState('');
  const [isNYBModalOpen, setIsNYBModalOpen] = useState(false);
  const [isTnCModalOpen, setIsTnCModalOpen] = useState(false);
  const [isBkDownloadBillButtonDisabled, setIsBkDownloadBillButtonDisabled] = useState(false);

  const { toast } = useToasts();
  const value = vh(100) - 100 - vh(20);
  function vh(v) {
    const clientHeight = document.documentElement.clientHeight || 0;
    const innerHeight = window.innerHeight || 0;
    const h = Math.max(clientHeight, innerHeight);
    return (v * h) / 100;
  }
  const billTemplateRef = useRef();

  const [isPaymentStatus, setPaymentStatus] = useState(false);

  const beforeUnloadHandler = (event) => {
    event.preventDefault();
    event.returnValue = true;
  };

  var loading = false;
  const PayNowBtn = async () => {
    loading = true;
    const options = {
      key: parsedData?.billAmountData?.rzpPublicKey,
      amount: parsedData?.billAmountData?.netPayableAmount,
      currency: parsedData?.billAmountData?.saleCurrency,
      order_id: parsedData?.paymentData?.rzpPaymentOrderId,
      handler: async function (response) {
        {
          axios
            .get(`${config.host}/bill/` + billUid)
            .then(async (response) => {
              if (response.status === 204) return;
              if (response?.data?.bill?.parsedData?.paymentData?.status === 'paid') {
                setPaymentStatus(true);
                window.removeEventListener('beforeunload', beforeUnloadHandler);
              }
            })
            .catch((err) => console.log('Error in payment details', err));
        }
      },
      prefill: {
        name: parsedData?.customerData?.firstName + parsedData?.customerData?.lastName,
        email: parsedData?.customerData?.email,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#3399cc',
      },
      modal: {
        ondismiss: async function () {
          window.removeEventListener('beforeunload', beforeUnloadHandler);
        },
      },
    };

    const rzpInstance = new window.Razorpay(options);
    rzpInstance.on('payment.failed', function (response) {
      toast(response.error.description, 'failed');
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    });
    rzpInstance.open();
    if (loading) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    }
  };

  const handleDownloadBillClick = async () => {
    const isBillLargerCopy = isBillLarger;
    const { isManyavar, shouldDownloadPngImg } = flags;

    window.onerror = (msg, url, lineNo, columnNo, error) => {
      console.log('Window onError:--', msg);
    };
    toast('Your bill is being downloaded. Please wait', 'success');
    if (shouldDownloadPngImg) {
      const downloadWindow = window.open(billImageUrl);
      downloadWindow?.blur();
      return;
    }
    if (isManyavar) {
      await axios
        .get(`${config.host}/download-pdf/${billUid}`, {
          responseType: 'blob',
          withCredentials: true,
        })
        .then(async (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const contentDesposition = response.headers['content-disposition'];
          let fileName = `${billUid}.pdf`;
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDesposition);
          if (matches?.[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => toast('Something went wrong downloading pdf.', 'error'));
      return;
    }

    setTimeout(() => {
      setIsBkDownloadBillButtonDisabled(true);
      setIsBillLarger(false);
    }, 500);

    setTimeout(() => {
      window.print();
    }, 3000);

    setTimeout(() => {
      setIsBkDownloadBillButtonDisabled(false);
      if (billImageSize || billTemplateSize || isBillLargerCopy) {
        setIsBillLarger(true);
      }
    }, 4000);
  };
  useEffect(() => {
    if (POBSetting?.visibility > 0) {
      setTimeout(() => {
        setPOBSetting({ ...POBSetting, visibility: 0 });
        setIsPopupOverBillModalOpen(true);
      }, 1000 * POBSetting?.visibility);
    }
    if (POBSetting?.visibility > 0 && isPopupOverBillModalOpen) {
      setIsPopupOverBillModalOpen(false);
    }
    if (POBSetting?.closeBtnDelay > 0 && POBSetting?.visibility === 0)
      setTimeout(
        () => setPOBSetting({ ...POBSetting, closeBtnDelay: 0 }),
        1000 * POBSetting.closeBtnDelay,
      );

    if (POBSetting?.visibility === 0) setIsPopupOverBillModalOpen(true);
  }, [POBSetting?.visibility, POBSetting?.closeBtnDelay]);
  useEffect(() => {
    setPOBSetting({
      visibility: campaignDetailsPOB?.setting?.visibility,
      closeBtnDelay: campaignDetailsPOB?.setting?.closeBtnDelay,
    });
  }, [campaignDetailsPOB]);

  useDebouncedEffect(
    () => {
      if (bsfRating) {
        setBestFeed({ rating: bsfRating });
        if (bsfRating >= 9) {
          if (flags.isBaggitFeedback)
            window.open(
              'https://www.google.com/search?q=baggit+store+vashi&rlz=1C1VDKB_enIN995IN995&biw=1536[…]1j0i512i457k1j0i402k1j0i22i30k1j33i22i29i30k1.635.69uGZyvxiKE',
            );
          if (flags.isMODFeedback)
            window.open(
              'https://www.google.com/search?q=mad+over+donuts+mumbai+charni+road&rlz=1C1VDKB_enIN995IN995&biw=1536&bih=754&tbm=lcl&sxsrf=APq-WBs5rvf8x_j2KGBqnsDpTkvCxLN9Sw%3A1649846108624&ei=XKdWYpPkJYO9mAW3gZ2wCQ&oq=mad+over+donuts+mumbai+charni+road&gs_l=psy-ab.3...4828.6186.0.6556.7.7.0.0.0.0.219.783.0j2j2.4.0....0...1c.1.64.psy-ab..3.4.779...35i304i39k1j0i546k1j33i10k1.0.G9I1nOfcTrE#lrd=0x3be7ce7624482e33:0xa2680233c7bf1c95,3,,,&rlfi=hd:;si:11702606053140012181,l,CiJtYWQgb3ZlciBkb251dHMgbXVtYmFpIGNoYXJuaSByb2FkIgOIAQFIjb-8muaAgIAIWkIQABABEAIQBBAFGAAYARgCGAMYBSIibWFkIG92ZXIgZG9udXRzIG11bWJhaSBjaGFybmkgcm9hZCoICAIQABABEAKSAQpkb251dF9zaG9wmgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVVF3TldJdFozRkJSUkFCqgEXEAEqEyIPbWFkIG92ZXIgZG9udXRzKAA;mv:[[19.235714899999998,73.0310125],[18.9556924,72.7920377]]',
            );
        }
      }
    },
    [bsfRating],
    350,
  );

  // removing DOM events on component unmount to safegaurd from memory leaks.
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const showModal = urlParams.get('showModal');
    if (showModal === 'tnc') {
      setIsTnCModalOpen(true);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, []);
  useEffect(() => {
    if (flags.showGoogleAdsForCampaigns) {
      setPOBSetting({ closeBtnDelay: 2, visibility: 1 });
    }
  }, []);

  const feedbackElem = (isCustomView = false) => (
    <Feedback
      bsfRating={bsfRating}
      setBsfRating={setBsfRating}
      NYBStatusFeedback={NYBStatusFeedback}
      setNYBStatusFeedback={setNYBStatusFeedback}
      billRatingHt={billRatingHt}
      setBillRatingHt={setBillRatingHt}
      bestFeedFinished={bestFeed?.isFinished}
      flags={flags}
      {...props}
      isCustomView={isCustomView}
    />
  );

  const handleDownloadSpecificHTML = async (elementId) => {
    //add multiplue calls coz dumbass safari sucks ass
    await toJpeg(document.getElementById(elementId));
    await toJpeg(document.getElementById(elementId));
    const result = await toJpeg(document.getElementById(elementId));

    download(result, 'fanz-card.jpeg');
  };
  return (
    <React.Fragment>
      {/* TODO: pass showGoogleAdsForCampaigns to campaign content embedding mechanism  */}
      {flags.showGoogleAdsForCampaigns ? (
        <AdsComponent adSlot="4347907412" height={'200px'} display={'block'} />
      ) : null}
      <Layout flags={flags} {...props} campaignDetails={campaignDetails}>
        <React.Suspense fallback={<></>}>{flags.isBK && <BurgerKingFonts />}</React.Suspense>
        <PopOverBill
          handlePOBClick={handlePOBClick}
          POBSetting={POBSetting}
          isPopupOverBillModalOpen={isPopupOverBillModalOpen}
        />
        {!flags.showGoogleAdsForCampaigns ? (
          <CampaignType type={campaignTypes.bannerInbill} />
        ) : null}
        <PropForwardWrapper {...(isSammm && { divProps: { className: 'sammm-bg' } })}>
          <Header
            place={props.parsedData?.customData?.place}
            bestFeedRating={bestFeed?.rating}
            bestFeedFinished={bestFeed?.isFinished}
          />
          <PropForwardWrapper
            divProps={{
              className: isWowMomos ? 'wowmomos-bg' : '',
            }}>
            <Payment
              props={props}
              PayNowBtn={PayNowBtn}
              isPaymentStatus={isPaymentStatus}
              flags={flags}
            />
            {feedbackElem()}
            <Body
              isBillLarger={isBillLarger}
              setIsBillLarger={setIsBillLarger}
              billImageSize={billImageSize}
              setBillImageSize={setBillImageSize}
              billTemplateSize={billTemplateSize}
              vh={value}
              setBillTemplateSize={setBillTemplateSize}
              ref={billTemplateRef}
              bestFeed={bestFeed}
              setBestFeed={setBestFeed}
              feedbackElem={feedbackElem}
              isPaymentStatus={isPaymentStatus}
            />
            {!flags.hideBillTear && (
              <$Block padding={(flags?.isCarnival || flags?.isPrasads) && '0 20px'}>
                {flags?.isConnplex ? (
                  <div className="connplexbottomdesign" />
                ) : (
                  <img
                    src={Tear}
                    className={`${isRzpPos || isManyavar || flags?.isGoColors || isWowMomos ? 'billTearCustomStyle' : 'billTearStyle'}`}
                  />
                )}
              </$Block>
            )}
            <SocialTopRow
              isNYBModalOpen={isNYBModalOpen}
              setIsNYBModalOpen={setIsNYBModalOpen}
              NYBStatusFeedback={NYBStatusFeedback}
              setNYBStatusFeedback={setNYBStatusFeedback}
              isTnCModalOpen={isTnCModalOpen}
              setIsTnCModalOpen={setIsTnCModalOpen}
              handleDownloadBillClick={handleDownloadBillClick}
              isBkDownloadBillButtonDisabled={isBkDownloadBillButtonDisabled}
              setIsBkDownloadBillButtonDisabled={setIsBkDownloadBillButtonDisabled}
              ref={billTemplateRef}
            />{' '}
            {!flags.fbThroughSurvey && <CampaignType type={campaignTypes?.surveyInBill} />}
            <div style={{ margin: `${theme.spacing.m} 0` }}>
              {flags.showGoogleAdsForCampaigns ? (
                <AdsComponent adSlot="5444082803" height={'280px'} />
              ) : (
                <CampaignType type={campaignTypes.adBelowBill} flags={flags} {...props} />
              )}
            </div>
            <CampaignType type={campaignTypes.sellBelowBill} />
            <SocialBottomRow
              handleDownloadSpecificHTML={handleDownloadSpecificHTML}
              handleDownloadBillClick={handleDownloadBillClick}
            />
          </PropForwardWrapper>
        </PropForwardWrapper>
      </Layout>
    </React.Fragment>
  );
};

// export { BillView } from './BillView'
export default BillView;
