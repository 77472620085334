import React from 'react';

import {
  XTwitterIconofIWAI,
  YoutubeIconofofIWAI,
  PublicAppIconofIWAI,
  FacebookIconofIWAI,
  LinkedInIconofIWAI,
  WhatsAppIconofIWAI,
  SuggestionIconofIWAI,
  ContactUsIconofIWAI,
} from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

export const InlandWaterwaysSocial = () => (
  <$Flexbox className="Socialalign" justify="space-around" padding="16px 0px" alignItems="center">
    <a href="https://x.com/IWAI_ShipMin/" target="_blank" rel="noopener noreferrer">
      <XTwitterIconofIWAI style={{ width: '30px', height: '30px' }} />
    </a>
    <a href="https://www.youtube.com/@IWAI_ShipMin" target="_blank" rel="noopener noreferrer">
      <YoutubeIconofofIWAI style={{ width: '30px', height: '30px' }} />
    </a>
    <a
      href="https://public.app/user/profile/MdZA8S7Z9bPyPVsIdNyvZuh6qJk1"
      target="_blank"
      rel="noopener noreferrer">
      <PublicAppIconofIWAI style={{ width: '30px', height: '30px' }} />
    </a>
    <a href="https://www.facebook.com/IWAI.MoPSW" target="_blank" rel="noopener noreferrer">
      <FacebookIconofIWAI style={{ width: '33px', height: '30px' }} />
    </a>
    <a
      href="https://www.linkedin.com/company/inland-waterways-authority-of-india/?originalSubdomain=in"
      target="_blank"
      rel="noopener noreferrer">
      <LinkedInIconofIWAI style={{ width: '33px', height: '30px' }} />
    </a>
    <a
      href="https://www.whatsapp.com/channel/0029Vaa3RCU1t90X2vW7l62l"
      target="_blank"
      rel="noopener noreferrer">
      <WhatsAppIconofIWAI style={{ width: '33px', height: '30px' }} />
    </a>
    <a
      href="https://iwai.nic.in/suggestion-feedback-form"
      target="_blank"
      rel="noopener noreferrer">
      <SuggestionIconofIWAI style={{ width: '33px', height: '30px' }} />
    </a>
    <a href="https://iwai.nic.in/contact-us" target="_blank" rel="noopener noreferrer">
      <ContactUsIconofIWAI style={{ width: '33px', height: '30px' }} />
    </a>
  </$Flexbox>
);
